import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBRow,
} from "mdb-react-ui-kit";
import { FC, useEffect, useState } from "react";
import { ISectionTitle } from "../../models/common/common";
import { InfoExperience } from "../../models/common/enums";
import { TravelExperiencesContent } from "../../models/home";
import styles from "../../styles/home/Home.TravelExperiences.module.scss";
import {
    travelExperiencesSubTitle,
    travelExperiencesTitle,
} from "../../utils/mockData/homeMocks";
import { premiumContent, qualityContent } from "../../utils/mockData/infoMock";
import { randomUid } from "../../utils/randomIds";
import contactsSvg from "../../utils/svg-icon/contacts";
import generalSvg from "../../utils/svg-icon/general";
import otherSvg from "../../utils/svg-icon/other";
import InfoModalGeneral from "../common/info/InfoModalGeneral";
import SectionTitle from "../common/SectionTitle";

const TravelExperiences: FC = () => {
    const { CatPremium, CatQuality } = otherSvg;
    const { Star } = generalSvg;
    const { InfoCircleLine } = contactsSvg;

    const [show, setShow] = useState(false);
    const [infoKey, setInfoKey] = useState<InfoExperience>(
        InfoExperience.QUALITY
    );

    const toggleModal = (infoExperience: InfoExperience) => {
        setInfoKey(infoExperience);
        setShow(true);
    };

    const infoContent = {
        [InfoExperience.QUALITY]: qualityContent,
        [InfoExperience.PREMIUM]: premiumContent,
    };

    const contents: TravelExperiencesContent[] = [
        {
            title: "Quality",
            icon: CatQuality,
            tags: [
                { id: randomUid(), value: "4* Hotels" },
                { id: randomUid(), value: "Small groups" },
                { id: randomUid(), value: "Pre & post sale support" },
                { id: randomUid(), value: "Italian Experts" },
            ],
            onClick: () => toggleModal(InfoExperience.QUALITY),
        },
        {
            title: "Premium",
            icon: CatPremium,
            tags: [
                { id: randomUid(), value: "5* or 4*S Hotels" },
                { id: randomUid(), value: "Private experiences" },
                { id: randomUid(), value: "Pre & post sale support" },
                { id: randomUid(), value: "Italian Experts" },
            ],
            onClick: () => toggleModal(InfoExperience.PREMIUM),
        },
    ];

    const [itemList, setItemList] = useState<
        { value: TravelExperiencesContent; id?: string }[]
    >([]);

    useEffect(() => {
        setItemList(
            contents.map((item: TravelExperiencesContent) => ({
                value: item,
                id: randomUid(),
            }))
        );
    }, []);

    const title: ISectionTitle = {
        title: travelExperiencesTitle,
        subTitle: travelExperiencesSubTitle,
        alignment: "justify-content-center text-center",
        fluid: true,
        classNameSubTitle: styles.travelExperiencesSubTitle,
        classNameTitle: styles.travelExperiencesTitle,
        classNameTitleContainer: styles.travelExperiencesTitleContainer,
    };

    return (
        <MDBContainer
            fluid
            className={`${styles.travelExperiences} justify-content-center px-0 py-5`}
        >
            <div className="px-2">
                <SectionTitle sectionTitle={title} />
            </div>
            <MDBRow>
                <MDBCol xs={0} sm={0} lg={2} xl={2} xxl={3} />
                <MDBCol xs={12} lg={8} xl={8} xxl={6}>
                    <MDBContainer fluid>
                        <MDBRow className="row-cols-1 overflow-hidden">
                            {itemList.map(({ id, value }) => (
                                <MDBCol
                                    key={id}
                                    xs={12}
                                    sm={6}
                                    xxl={6}
                                    className="mt-4 mt-sm-0 p-4 p-lg-2"
                                >
                                    <MDBCard
                                        className={`${styles.catCard} h-100`}
                                    >
                                        <MDBCardBody className="text-start">
                                            <MDBRow>
                                                <MDBCol size={8} md={7} xl={8}>
                                                    <h4
                                                        className={
                                                            styles.catCardContentTitle
                                                        }
                                                    >
                                                        {value.title}
                                                    </h4>
                                                </MDBCol>
                                                <MDBCol
                                                    className={styles.info}
                                                    onClick={value.onClick}
                                                >
                                                    <p>More info</p>
                                                    <InfoCircleLine />
                                                </MDBCol>
                                            </MDBRow>
                                            <div className="text-center py-2">
                                                <value.icon />
                                            </div>
                                            <div
                                                className={
                                                    styles.catCardContent
                                                }
                                            >
                                                {value.tags.map(el => (
                                                    <div
                                                        key={el.id}
                                                        className="d-flex align-center mb-4"
                                                    >
                                                        {/* <MDBRow>
                                                            <MDBCol size={1}>
                                                                <Star />
                                                            </MDBCol>
                                                            <MDBCol center>
                                                                <p className="ms-2">
                                                                    {el.value}
                                                                </p>
                                                            </MDBCol>
                                                        </MDBRow> */}
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Star />
                                                            <div className="ms-2">
                                                                {el.value}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBContainer>
                </MDBCol>
            </MDBRow>
            <InfoModalGeneral
                content={infoContent[infoKey]}
                show={show}
                setShow={setShow}
            />
        </MDBContainer>
    );
};

export default TravelExperiences;
